export default {
    configItems: [
      {
        image: 'https://via.placeholder.com/140x140.jpg?text=Settings',
        title: 'General Settings',
        items: [
          {
            name: 'Settings',
            link: '/profile/settings'
          },
          {
            name: 'Profile',
            link: '/profile/profile'
          },
          {
            name: 'Agenda',
            link: '/agenda'
          }
        ]
      },
      {
        image: 'https://via.placeholder.com/140x140.jpg?text=Settings',
        title: 'Company',
        items: [
          {
            name: 'My Company',
            link: '/agency'
          },
          {
            name: 'Edit my company',
            link: '/agency/edit'
          },
          {
            name: 'Edit my category',
            link: '/agency/category'
          }
        ]
    },
    {
      image: 'https://via.placeholder.com/140x140.jpg?text=Settings',
      title: 'Events',
      items: [
        
        {
          name: 'Rdv',
          link: '/events/rdvlist'
        },
        {
          name: 'Event',
          link: '/events/eventlist'
        },
        {
          name: 'Task',
          link: '/events/tasklist'
        }
      ]
    },
      {
        image: 'https://via.placeholder.com/140x140.jpg?text=Settings',
        title: 'Services',
        items: [
          {
            name: 'My services',
            link: '/service/list'
          },
          {
            name: 'Add Service',
            link: '/service/add'
          }
        ]
      },
      {
        image: 'https://via.placeholder.com/140x140.jpg?text=Settings',
        title: 'Agenda',
        items: [
          {
            name: 'My Agenda',
            link: '/agenda'
          },
          {
            name: 'Add Agenda',
            link: '/agenda/add'
          },
          {
            name: 'Edit Agenda',
            link: '/agenda/edit'
          },
          {
            name: 'View Agenda',
            link: '/agenda/view'
          }
        ]
      }
    ]
  };
  