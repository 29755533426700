<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mt-4 p-mb-4"
      />
      <div class="card">
        <h3 class="font-bold text-3xl text-left">Update Employee</h3>
        <form
          class="text-left mt-4"
          ref="EmployeeForm"
          @submit.prevent="onSave"
        >
          <div class="form-input p-mt-3">
          <p class="font-semibold text mt-2">Role</p>
            <Dropdown
              id="employee.role"
              v-model="employee.role"
              :options="roles"
              optionLabel="name"
              optionValue="code"
              placeholder="Select Role"
            ></Dropdown>
          </div>
          <div class="form-input p-mt-3">
            <p class="font-semibold text mt-2">{{ i18n.$t("First Name") }} </p>
            <InputText
                v-model="employee.first_name"
                type="first_name"
                name="first_name"
                class="border border-teal-500 rounded p-1"
                required
              />
          </div>
          <div class="form-input p-mt-3">
            <p class="font-semibold text mt-2">{{ i18n.$t("Email") }}</p>
            <InputText
              v-model="employee.email"
              type="email"
              name="email"
              class="border border-teal-500 rounded p-1"
              required
            />
          </div>
          <div class="form-input p-mt-3">
            <p class="font-semibold text mt-2">{{ i18n.$t("Telephone") }} </p>
            <input-phone
              v-model="employee.phone"
              type="text"
              placeholder="Phone Number"
              name="phone"
              :getNumber="employee.phone"
            />
          </div>

          <div v-show="isOption">
            <Button
              @click="options(false)"
              label="hide options"
              class="p-button-link"
            />
            <div class="form-input p-mt-3">
              <p class="font-semibold text mt-2">{{ i18n.$t("Last Name") }} </p>
              <InputText
                  v-model="employee.last_name"
                  type="last_name"
                  name="last_name"
                  class="border border-teal-500 rounded p-1"
                />
            </div>
            <div class="form-input p-mt-3">
              <p class="font-semibold text mt-2">{{ i18n.$t("Address") }}</p>
              <Textarea
                  inputId="textarea"
                  rows="3"
                  cols="30"
                  v-model="employee.address"
                ></Textarea>
            </div>
          </div>

          <div v-show="!isOption">
            <Button
              @click="options(true)"
              label="display options"
              class="p-button-link"
            />
          </div>

          <InputText
            type="hidden"
            name="organisation_id"
            v-model="employee.organisation_id"
          />
          <input type="hidden" name="username" v-model="employee.username" />
          <InputText
            type="hidden"
            name="language_id"
            v-model="employee.language_id"
          />
          <InputText
            type="hidden"
            name="currency_id"
            v-model="employee.currency_id"
          />

          <input type="hidden" name="status" v-model="employee.status" />

          <div v-if="showResult" class="mt-2">
            <p v-if="isError" class="font-semibold text-red-600">
              Oops, something went wrong.
            </p>
            <p v-else class="font-semibold text-green-600">
              Saved successfully.
            </p>
          </div>

          <br /><br />
          <div class="form-input p-mt-4 d-flex">
            <Button type="submit" class="font-semibold loading-btn">
              <spinner v-if="isLoading" color="white" />
              <p class="m-0" v-if="!isLoading">Save</p>
            </Button>
            <Button class="font-semibold p-button-warning loading-btn" @click="$router.push({ path: `/employee/list` })">
              <p class="m-0">Cancel</p>
            </Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue';
import axios from '@/plugins/axios';
import Spinner from '@/components/UI/Spinner.vue';
import { useRoute } from 'vue-router';
import useI18n from '@/plugins/useI18n';
import EmployeeModel from '@/models/employee';
import { API } from '@/plugins/api';
import { roles } from '@/store/constant';
export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/' },
    breadcrumbItems: [{ label: 'Employee list', to: '/employee/list' }]
  }),
  components: {
    Spinner
  },
  setup() {
    const { i18n } = useI18n();
    const route = useRoute();
    const EmployeeForm = ref();
    const employee = reactive(new EmployeeModel({}));
    const showResult = ref(false);
    const isError = ref(false);
    const isLoading = ref(false);
    const isOption = ref(false);


    axios.get(`user/${route.query.employee}`).then((response) => {
      const data = response.data.data.items[0];
      console.log(data, 'employee');
      for (const key in data) {
        employee[key] = data[key];
      }
    });

    const api = new API();
    
    async function onSave() {
      isLoading.value = true;
      const id = route.query.employee;
      const routePath = { path: '/employee/list' };
      api.update('user', 'Employee', id, employee, routePath);
      isLoading.value = false;
    }

    async function options(visibility) {
      isOption.value = visibility;
    }

    return {
      i18n,
      EmployeeForm,
      employee,
      roles,
      showResult,
      isError,
      isLoading,
      onSave,
      options,
      isOption
    };
  }
};
</script>

<style scoped>
.form-input {
  width: 100%;
  max-width: 320px;
}
.form-input input {
  width: 100%;
}
.loading-btn {
  height: 42px;
  width: 150px;
}
.router-link {
  text-decoration: underline;
  color: #28476f;
}
.d-flex{
  display: flex;
  justify-content: space-between;
}
.m-0{
  margin: 0 auto;
}
</style>
