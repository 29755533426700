<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mt-4 p-mb-4"
      />
      <div class="card">
        <form class="text-left mt-4" @submit.prevent="onSave">
          <h3 class="font-bold">{{ i18n.$t("Add a new group") }}</h3>
          <div class="form-input">
            <p class="font-semibold">{{ i18n.$t("Name") }}</p>
            <InputText v-model="group.name" required />
          </div>
          <div class="form-input">
            <p class="font-semibold">{{ i18n.$t("Description") }}</p>
            <QuillEditor
              toolbar="minimal"
              v-model:content="group.description"
              :content="group.description"
              contentType="html"
            />
          </div>
          <div class="form-input">
            <p class="font-semibold">{{ i18n.$t("Type") }}</p>
            <div class="radio-input">
              <RadioButton
                id="visible"
                name="_type"
                value="Visible"
                v-model="group._type"
                required
              />
              <label for="visible">{{ i18n.$t("Visible") }}</label>
            </div>
            <div class="radio-input">
              <RadioButton
                id="invisible"
                name="_type"
                value="Invisible"
                v-model="group._type"
                required
              />
              <label for="invisible">{{ i18n.$t("Invisible") }}</label>
            </div>
            <div class="radio-input">
              <RadioButton
                id="choosemember"
                name="_type"
                value="Choosemember"
                v-model="group._type"
                required
              />
              <label for="choosemember">{{ i18n.$t("ChooseMember") }}</label>
            </div>
          </div>
          <div class="form-input">
            <p class="font-semibold">{{ i18n.$t("Case") }}</p>
            <div class="radio-input">
              <RadioButton
                id="contact"
                name="_case"
                value="Contact"
                v-model="group._case"
                required
              />
              <label for="contact">{{ i18n.$t("Contact") }}</label>
            </div>
            <div class="radio-input">
              <RadioButton
                id="subscriber"
                name="_case"
                value="Subscriber"
                v-model="group._case"
                required
              />
              <label for="subscriber">{{ i18n.$t("Subscriber") }}</label>
            </div>
          </div>
          <div class="form-input">
            <transition-group name="p-messages" tag="div">
              <Message
                v-for="msg of message"
                :severity="msg.severity"
                :key="msg.content"
              >
                {{ msg.content }}
              </Message>
            </transition-group>
          </div>
          <div class="form-input">
            <Button type="submit" class="loading-btn font-semibold p-mt-4">
              <spinner v-if="isLoading" color="white" />
              <span v-if="!isLoading">{{ i18n.$t("Save") }}</span>
            </Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, reactive  } from 'vue';
import { useRouter } from 'vue-router';
import axios from '@/plugins/axios';
import Spinner from '@/components/UI/Spinner.vue';
import useI18n from '@/plugins/useI18n';
import GroupModel from '@/models/group';
import { QuillEditor } from '@vueup/vue-quill';

export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [
      { label: 'My Clients', to: '/contacts' },
      { label: 'My Groups', to: '/groups/list' }
    ]
  }),
  components: {
    Spinner,
    QuillEditor
  },
  setup(){
    const { i18n } = useI18n();
    const router = useRouter();
    const group = reactive(new GroupModel({}));
    const isLoading = ref(false);
    const message = ref([]);

    async function onSave() {
      isLoading.value = true;
      message.value = [];
      try {
        await axios.post('group', group);
        message.value = [
          { severity: 'success', content: 'Saved successfully' }
        ];
        router.push({ path: `/groups/list` });
      } catch (error) {
        console.log('[error]', error);
        message.value = [
          { severity: 'error', content: 'Oops, something went wrong!' }
        ];
      }
      isLoading.value = false;
    }
    return { 
      i18n, 
      group, 
      isLoading, 
      message, 
      onSave 
    };
  }
};
</script>

<style lang="scss" scoped>
.form-input {
  width: 100%;
  max-width: 360px;
  margin-top: 10px;
  input {
    width: 100%;
  }
}
.form-input textarea {
  width: 100%;
  min-height: 80px;
}
.loading-btn {
  width: 100%;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.radio-input {
  display: flex;
  align-items: center;
  margin-top: 3px;
}
.radio-input label {
  margin-left: 10px;
}
</style>
