<template>
  <div class="configuration mt-2">
    <div class="title">Configuration</div>
    <div class="config-cards">
      <div
        v-for="config in configItems"
        :key="config.title"
        class="config-card-item"
      >
        <config-card
          :title="config.title"
          :image="config.image"
          :items="config.items"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ConfigCard from '../components/Configuration/ConfigCard.vue';
import configs from '../store/data/config';
import useI18n from '@/plugins/useI18n';
export default {
  components: {
    ConfigCard
  },
  setup() {
    const { i18n } = useI18n();
    const configItems = configs.configItems;
    return { i18n, configItems };
  }
};
</script>
<style lang="scss" scoped>
.title {
  color: #000aaa;
}
.config-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.config-card-item {
  width: 360px;
  padding: 10px;
}
</style>
