<template>
  <div class="config-card">
    <p class="config-title mt-1">{{ title }}</p>
    <ul class="settings">
      <li class="setting-item" v-for="item in items" :key="item.name">
        <router-link :to="item.link">{{ item.name }} </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    image: {
      type: String,
      default: 'https://via.placeholder.com/140x140.jpg?text=Settings'
    },
    title: String,
    items: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.config-card {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 30px;
  width: 100%;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 200px;
}
ul.settings {
  width: 100%;
}
.setting-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  cursor: pointer;
  color: #06adef;
}
</style>
