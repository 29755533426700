<template>
  <div class="p-grid">
    <div class="p-col-12">
      <TopBarBreadCrumb
        :breadcrumbHome="breadcrumbHome"
        :breadcrumbItems="breadcrumbItems"
      />

      <div class="card">
        <div class="p-col-6">
          <h3 class="font-semibold">{{ i18n.$t('View') }} {{ event._type }}</h3>
          <div class="form-input p-mt-3">
            <label>{{ i18n.$t('Name') }}: </label>
            {{ event.name }}
          </div>
          <div class="form-input p-mt-3">
            <label>{{ i18n.$t('Description') }} : </label>
            {{ event.description }}
          </div>
          <div class="form-input p-mt-3">
            <label>{{ i18n.$t('Address') }} : </label>
            {{ event.address }}
          </div>
          <div class="form-input p-mt-3">
            <label>{{ i18n.$t('Date') }}: </label>
            {{ event.date }}
          </div>
          <div class="form-input p-mt-3">
            <label>{{ i18n.$t('Repeat') }} : </label>
            {{ event.repeat }}
          </div>
          <div class="form-input p-mt-3">
            <label>{{ i18n.$t('Importance') }}: </label>
            {{ event.importance }}
          </div>
          <div class="form-input p-mt-3">
            <label>{{ i18n.$t('Icon') }} : </label>
            {{ event.icon }}
          </div>
          <div class="form-input p-mt-3">
            <label>{{ i18n.$t('Expires at') }} : </label>
            {{ event.expires_at }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { GET_CONTACTS } from '@/store/types';
import axios from '@/plugins/axios';
import useI18n from '@/plugins/useI18n';
import moment from 'moment';
import EventModel from '@/models/event';

export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [
      { label: 'My Task', to: '/events/tasklist?_type=Task' },
      { label: 'My Rdv', to: '/events/rdvlist?_type=Rdv' },
      { label: 'My Event', to: '/events/eventlist?_type=Event' }
    ],
    RDvdata: ''
  }),
  computed: {
    RDvdatas() {
      return this.$store._state.data.events.events;
    }
  },
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const route = useRoute();
    const type = ref();

    const query = route.query;

    type.value = query && query._type ? query._type : 'Rdv';

    const contacts = computed(() => store.getters[GET_CONTACTS]);

    var contactData =
      contacts &&
      contacts.value &&
      contacts.value.map((i, k) => {
        return i._id;
      });

    const event = reactive(new EventModel({ __participants_id: contactData }));

    const eventId = query.event;

    axios.get(`event/${eventId}`).then((response) => {
      const {
        _id,
        _type,
        name,
        address,
        repeat,
        description,
        importance,
        duration,
        icon,
        files,
        expires_at,
        _status,
        date
      } = response.data.data.items[0];

      event._id = _id;
      event._type = _type;
      event.name = name;
      event.date = moment(date).format('DD/MM/YYYY h:mm');
      event.description = description;
      event._status = _status;
      event.address = address;
      event.repeat = repeat;
      event.importance = importance;
      event.duration = duration;
      event.expires_at = expires_at
        ? moment(expires_at).format('DD/MM/YYYY h:mm')
        : null;
      event.files = files;
      event.icon = icon;
    });

    return { i18n, type, event };
  }
};
</script>