<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mt-4 p-mb-4"
      />
      <div class="card">
        <form @submit.prevent="onSave">
          <h3 class="font-bold">{{ i18n.$t('Edit group') }}</h3>
          <div class="form-input">
            <p class="font-semibold">{{ i18n.$t('Name') }}</p>
            <InputText v-model="group.name" required />
          </div>
          <div class="form-input p-mt-2">
            <p class="font-semibold">{{ i18n.$t('Description') }}</p>
            <QuillEditor
              toolbar="minimal"
              v-model:content="group.description"
              :content="group.description"
              contentType="html"
              @ready="quillReady"
            />
          </div>
          <div class="form-input p-mt-2">
            <p class="font-semibold">{{ i18n.$t('Type') }}</p>
            <div class="radio-input p-mt-1">
              <RadioButton
                id="visible"
                name="_type"
                value="Visible"
                v-model="group._type"
                required
              />
              <label for="visible">{{ i18n.$t('Visible') }}</label>
            </div>
            <div class="radio-input p-mt-1">
              <RadioButton
                id="invisible"
                name="_type"
                value="Invisible"
                v-model="group._type"
                required
              />
              <label for="invisible">{{ i18n.$t('Invisible') }}</label>
            </div>
            <div class="radio-input p-mt-1">
              <RadioButton
                id="choosemember"
                name="_type"
                value="Choosemember"
                v-model="group._type"
                required
              />
              <label for="choosemember">{{ i18n.$t('ChooseMember') }}</label>
            </div>
          </div>

          <div class="form-input">
            <p class="font-semibold">{{ i18n.$t('Case') }}</p>
            <div class="radio-input">
              <RadioButton
                id="contact"
                name="_case"
                value="Contact"
                v-model="group._case"
                required
              />
              <label for="contact">{{ i18n.$t('Contact') }}</label>
            </div>
            <div class="radio-input">
              <RadioButton
                id="subscriber"
                name="_case"
                value="Subscriber"
                v-model="group._case"
                required
              />
              <label for="subscriber">{{ i18n.$t('Subscriber') }}</label>
            </div>
          </div>

          <div class="form-input p-mt-2">
            <transition-group name="p-messages" tag="div">
              <Message
                v-for="msg of message"
                :severity="msg.severity"
                :key="msg.content"
              >
                {{ msg.content }}
              </Message>
            </transition-group>
          </div>
          <div class="form-input p-mt-4">
            <Button type="submit" class="loading-btn">
              <spinner v-if="isLoading" color="white" />
              <span v-if="!isLoading">{{ i18n.$t('Save') }}</span>
            </Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue';
import { useRoute } from 'vue-router';
import axios from '@/plugins/axios';
import Spinner from '@/components/UI/Spinner.vue';
import useI18n from '@/plugins/useI18n';
import GroupModel from '@/models/group';
import { API } from '@/plugins/api';
import { QuillEditor } from '@vueup/vue-quill';

export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [
      { label: 'My Clients', to: '/contacts' },
      { label: 'My Groups', to: '/groups/list' }
    ]
  }),
  components: {
    Spinner,
    QuillEditor
  },
  setup() {
    const { i18n } = useI18n();
    const route = useRoute();
    const group = reactive(new GroupModel({}));

    const isLoading = ref(false);
    const message = ref([]);
    let quill = null;
    function quillReady(e) {
      quill = e;
    }
    axios.get(`group/${route.query.group}`).then((response) => {
      const data = response.data.data;
      for (const key in data) {
        group[key] = data[key];
      }
      quill.root.innerHTML = data.description;
    });
    //console.log(group, 'groupslistedit');

    const api = new API();

    async function onSave() {
      const id = route.query.group;
      const routePath = { path: `/groups/list` };
      api.update('group', 'Group', id, group, routePath);
    }
    return {
      i18n,
      group,
      message,
      isLoading,
      onSave,
      quillReady
    };
  }
};
</script>

<style lang="scss" scoped>
.form-input {
  width: 100%;
  max-width: 320px;
  input {
    width: 100%;
  }
  textarea {
    width: 100%;
  }
}

.loading-btn {
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  padding: 0;
}
</style>
