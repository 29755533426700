<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <h4>{{ i18n.$t("Profile") }}</h4>
        <Breadcrumb
          :home="breadcrumbHome"
          :model="breadcrumbItems"
          class="p-mt-4 p-mb-4"
        />

        <br />
        <div class="p-mb-2 p-lg-6 p-mb-lg-0">
          <p><strong>{{ i18n.$t("Username") }} </strong> > {{ userData.username }}</p>
          <p><strong>{{ i18n.$t("First Name") }} </strong> {{ userData.first_name }}</p>
          <p><strong>{{ i18n.$t("Last Name") }} </strong> {{ userData.last_name }}</p>

          <p><strong>{{ i18n.$t("Email") }} </strong> {{ userData.email }}</p>
          <p><strong>{{ i18n.$t("Role") }} </strong> {{ userData.role }}</p>

          <p><strong>{{ i18n.$t("Organisation") }} </strong> {{ userData.organisation_name }}</p>
          <p><strong>{{ i18n.$t("Telephone") }} </strong> {{ userData.phone }}</p>
          <p><strong>{{ i18n.$t("Address") }} </strong> {{ userData.address }}</p>
          <p><strong>{{ i18n.$t("Language") }} </strong> {{ userData.language_id }}</p>
          <p><strong>{{ i18n.$t("Currency") }} </strong> {{ userData.currency_id }}</p>

          <br /><br />
          {{ i18n.$t("For testing") }} :
          <p><strong>ID </strong> {{ userData.id }}</p>
          <p>
            <strong>{{ i18n.$t("Logout") }}Organisation ID </strong> {{ userData.organisation_id }}
          </p>
        </div>
      </div>
      <router-link to="/profile/profile">
        <Button label="Update" class="p-mr-2 p-mb-2"></Button>
      </router-link>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { formatDate } from '../../plugins/utility';
import useI18n from '@/plugins/useI18n';
import {useStore} from 'vuex';
import {GET_USERDATA} from '@/store/types'
export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
        breadcrumbItems: [
      { label: 'Organisation', to: '/agency' },
      { label: 'Profile', to: '/profile/myprofile' },
      { label: 'Employee', to: '/employee/list' }
    ]
  }),
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const userData = computed(() => store.getters[GET_USERDATA]);

    function displayDate(tm) {
      return formatDate(parseInt(tm) * 1000);
    }

    return { i18n, userData, displayDate };
  }
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}
td {
  padding: 5px;
}
</style>
