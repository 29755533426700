<template>
  <div class="text-left">
    <Breadcrumb
      :home="breadcrumbHome"
      :model="breadcrumbItems"
      class="p-mt-4 p-mb-4"
    />
    <h3 class="font-bold text-3xl text-left">{{ i18n.$t('Add Employee') }}</h3>
    <form class="text-left mt-4" @submit.prevent="onSave">
      <div class="form-input p-mt-3">
        <p class="font-semibold text mt-2">{{ i18n.$t('Role') }}</p>
        <Dropdown
          v-model="employee.role"
          :options="roles"
          optionLabel="name"
          placeholder="Select Role"
          required
        />
        <p v-if="showReq" class="requiredText">Required Role</p>
      </div>
      <div class="form-input p-mt-3">
        <p class="font-semibold text mt-2">{{ i18n.$t('First Name') }}</p>
        <InputText
          v-model="employee.first_name"
          type="first_name"
          name="first_name"
          class="border border-teal-500 rounded p-1"
          required
        />
      </div>

      <div class="form-input p-mt-3">
        <p class="font-semibold text mt-2">{{ i18n.$t('Email') }}</p>
        <InputText
          v-model="employee.email"
          type="email"
          name="email"
          class="border border-teal-500 rounded p-1"
          required
        />
      </div>

      <div v-show="isOption">
        <Button
          @click="options(false)"
          label="hide options"
          class="p-button-link"
        />
        <div class="form-input p-mt-3">
          <p class="font-semibold text mt-2">{{ i18n.$t('Last Name') }}</p>
          <InputText
            v-model="employee.last_name"
            type="last_name"
            name="last_name"
            class="border border-teal-500 rounded p-1"
          />
        </div>
        <div class="form-input p-mt-3">
          <p class="font-semibold text mt-2">{{ i18n.$t('Telephone') }}</p>

          <input-phone
            v-model="employee.phone"
            type="text"
            placeholder="Phone Number"
            name="phone"
          />
        </div>
        <div class="form-input p-mt-3">
          <p class="font-semibold text mt-2">{{ i18n.$t('Address') }}</p>
          <Textarea
            inputId="textarea"
            rows="3"
            cols="30"
            v-model="employee.address"
          ></Textarea>
        </div>
      </div>

      <div v-show="!isOption">
        <Button
          @click="options(true)"
          label="display options"
          class="p-button-link"
        />
      </div>
      <div>
        <input
          type="hidden"
          name="organisation_id"
          v-model="employee.organisation_id"
        />
        <input type="hidden" name="username" v-model="employee.username" />
        <input
          type="hidden"
          name="language_id"
          v-model="employee.language_id"
        />
        <input
          type="hidden"
          name="currency_id"
          v-model="employee.currency_id"
        />
        <input type="hidden" name="status" v-model="employee.status" />
      </div>
      <div v-if="showResult" class="mt-2">
        <p v-if="isError" class="font-semibold text-red-600">
          {{ i18n.$t('Oops, something went wrong') }}
        </p>
        <p v-else class="font-semibold text-green-600">
          {{ i18n.$t('Saved successfully') }}
        </p>
      </div>
      <div class="form-input p-mt-3 d-flex">
        <Button type="submit" class="font-semibold loading-btn">
          <spinner v-if="isLoading" color="white" />
          <span v-if="!isLoading">Save</span>
        </Button>
        <Button
          class="font-semibold p-button-warning loading-btn"
          @click="$router.push({ path: `/employee/list` })"
        >
          <span>Cancel</span>
        </Button>
      </div>
    </form>
  </div>
</template>
<script>
import { ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
import axios from '@/plugins/axios';
import Spinner from '@/components/UI/Spinner.vue';
import useI18n from '@/plugins/useI18n';
import EmployeeModel from '@/models/employee';
export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/' },
    breadcrumbItems: [{ label: 'Employee list', to: '/employee/list' }],
    isOption: false
  }),
  components: {
    Spinner
  },
  setup() {
    const { i18n } = useI18n();
    const router = useRouter();

    const employee = reactive(new EmployeeModel({}));

    const showResult = ref(false);
    const isOption = ref(false);
    const isError = ref(false);
    const isLoading = ref(false);
    const showReq = ref(false);

    const roles = [
      { name: 'manager', code: 'manager' },
      { name: 'agent', code: 'agent' }
    ];

    async function options(visibility) {
      isOption.value = visibility;
    }

    async function onSave() {
      isLoading.value = true;
      showResult.value = false;

      employee.role = employee.role.name;
      console.log(employee.role, 'role');
      if (typeof employee.role !== 'undefined') {
        showReq.value = true;
        try {
          await axios.post('user', employee);
          isError.value = false;
        } catch {
          isError.value = true;
        }

        if (!isError.value) {
          router.push({ path: '/employee/list' });
        }
        showResult.value = true;
      } else {
        showReq.value = true;
      }
      isLoading.value = false;
    }

    return {
      i18n,
      employee,
      roles,
      showResult,
      isError,
      isLoading,
      onSave,
      options,
      isOption,
      showReq
    };
  }
};
</script>

<style scoped>
.form-input {
  width: 100%;
  max-width: 320px;
}
.form-input input {
  width: 100%;
}
.loading-btn {
  height: 42px;
  width: 150px;
  justify-content: center;
}
.router-link {
  text-decoration: underline;
  color: #28476f;
}
.d-flex {
  display: flex;
  justify-content: space-between;
}
.requiredText {
  color: red;
}
</style>
