<template>
  <p class="font-semibold">{{ i18n.$t('Currency') }}</p>
  <Dropdown
    v-model="item"
    :options="currencyItems"
    optionLabel="name"
    optionValue="value"
    @change="$emit('select', item)"
    @onBlur="$emit('onBlur')"
  />
</template>

<script>
import { currencyItems } from '@/store/constant';
import useI18n from '@/plugins/useI18n';

export default {
  props: {
    value: {
      type: String
    }
  },
  setup(props) {
    const { i18n } = useI18n();
    const item = props.value ? props.value : 'EUR';
    return {
      currencyItems,
      i18n,
      item
    };
  }
};
</script>