<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mt-4 p-mb-4"
      />
      <div class="card">
        <h3 class="font-bold">{{ i18n.$t('Multi Assign Group') }}</h3>
        <form style="max-width: 360px" @submit.prevent="onSave">
          <div class="form-input">
            <MultiSelect
              name="multi-assign-group"
              v-model="selected"
              :options="groups"
              optionLabel="name"
              optionValue="_id"
            />
          </div>
          <div class="form-input p-mt-2">
            <transition-group name="p-messages" tag="div">
              <Message
                v-for="msg of message"
                :severity="msg.severity"
                :key="msg.content"
              >
                {{ msg.content }}
              </Message>
            </transition-group>
          </div>
          <div class="p-d-flex p-jc-between p-mt-4">
            <Button type="submit" class="font-semibold loading-btn">
              <spinner v-if="isLoading" color="white" />
              <span v-if="!isLoading">{{ i18n.$t('Save') }}</span>
            </Button>
            <Button
              type="button"
              class="p-button-outlined loading-btn"
              @click="$router.back()"
            >
              <span>{{ i18n.$t('Cancel') }}</span>
            </Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import Spinner from '../../components/UI/Spinner.vue';
import axios from '@/plugins/axios';
import { FETCH_GROUPS, GET_GROUPS, GET_USERDATA } from '../../store/types';

import useI18n from '@/plugins/useI18n';
export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [
      { label: 'My Clients', to: '/contacts' },
      { label: 'My Groups', to: '/groups/list' }
    ]
  }),
  components: {
    Spinner
  },
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const router = useRouter();
    const groups = computed(() => store.getters[GET_GROUPS]);
    const userData = computed(() => store.getters[GET_USERDATA]);
    const isLoading = ref(false);
    const message = ref([]);
    const selected = ref([]);
    const route = useRoute();
    store.dispatch(FETCH_GROUPS, userData.value.id);
    const contactIds = route.query.contact;

    async function onSave() {
      isLoading.value = true;
      message.value = [];
      try {
        for (const group of selected.value) {
          let items = [];
          contactIds.split(',').forEach((v) => {
            items.push({
              _id: v,
              invit_status: 'Sent'
            });
          });
          console.log(items);
          const res = await axios.post(`group/invit/${group}`, items);
        }
        message.value = [
          { severity: 'success', content: 'Saved successfully' }
        ];
        router.push({ path: `/contacts` });
      } catch {
        message.value = [
          { severity: 'error', content: 'Oops, something went wrong!' }
        ];
      }
      isLoading.value = false;
    }

    return { i18n, groups, selected, onSave, isLoading, message };
  }
};
</script>

<style scoped>
.loading-btn {
  width: 150px;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
