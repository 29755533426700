<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mt-4 p-mb-4"
      />
      <div class="card">
        <h3 class="font-bold text-3xl text-left">Employee Schecdule</h3>

        <div class="mt-5 mb-5">
          <RDV :weekDayTimes="scheduleData" />
        </div>
        <div class="form-input p-mt-4 d-flex">
          <Button class="font-semibold loading-btn" @click="onSave()">
            <spinner v-if="isLoading" color="white" />
            <p class="m-0" v-if="!isLoading">Save</p>
          </Button>
          <Button
            class="font-semibold p-button-warning loading-btn"
            @click="$router.push({ path: `/employee/list` })"
          >
            <p class="m-0">Cancel</p>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed } from 'vue';
import axios from '@/plugins/axios';
import Spinner from '@/components/UI/Spinner.vue';
import { useRoute } from 'vue-router';
import useI18n from '@/plugins/useI18n';
import EmployeeModel from '@/models/employee';
import { API } from '@/plugins/api';
import { roles } from '@/store/constant';
import RDV from '@/components/RDV/RDV.vue';
import { getBaseSchedule } from '@/store/data/schedule';

export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/' }
  }),
  components: {
    Spinner,
    RDV
  },
  setup() {
    const { i18n } = useI18n();
    const route = useRoute();
    const scheduleData = ref([]);
    const employee = reactive(new EmployeeModel({}));
    const showResult = ref(false);
    const isError = ref(false);
    const isLoading = ref(false);
    const breadcrumbItems = ref([
      { label: 'Employee list', to: '/employee/list' },
      {
        label: 'Employee',
        to: '/employee/edit?employee=' + route.query.employee
      },
      { label: 'Schedule' }
    ]);
    axios.get(`user/${route.query.employee}`).then((response) => {
      const data = response.data.data.items[0];

      //console.log(data, 'employee');
      for (const key in data) {
        employee[key] = data[key];
      }
      if (data.schedule) {
        scheduleData.value = data.schedule;
      } else {
        scheduleData.value = getBaseSchedule();
      }
    });

    const api = new API();

    async function onSave() {
      isLoading.value = true;
      const id = route.query.employee;
      //console.log(scheduleData.value);
      employee.schedule = scheduleData.value;
      const routePath = { path: '/employee/list' };
      await api.update('user', 'Employee', id, employee, routePath);
      isLoading.value = false;
    }

    return {
      i18n,
      scheduleData,
      employee,
      roles,
      showResult,
      isError,
      isLoading,
      onSave,
      breadcrumbItems
    };
  }
};
</script>

<style scoped>
.form-input {
  width: 100%;
  max-width: 320px;
}
.form-input input {
  width: 100%;
}
.loading-btn {
  height: 42px;
  width: 150px;
}
.router-link {
  text-decoration: underline;
  color: #28476f;
}
.d-flex {
  display: flex;
  justify-content: space-between;
}
.m-0 {
  margin: 0 auto;
}
</style>
