import {UserDataInfo} from '@/plugins/api'

const userData = new UserDataInfo();
export default class GroupModel {
  constructor(item) {
    if (item) {
      this.owner_id = userData.ownerId();
      this.organisation_id = userData.organisationId();
      this.name = item.name ? item.name : null;
      this.icon = item.icon ? item.icon : 'oneicon';
      this.__contacts_id = item.__contacts_id ? item.__contacts_id : null;
      this.description = item.description ? item.description : null;
      this._type = item._type ? item._type : null;
      this._case = item._case ? item._case : null;
    }
  }
}
