<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mt-4 p-mb-4"
      />
      <div class="card">
        <div>
          <Button @click="$router.push({ path: '/groups/add' })" v-if="can('v1.group.create')">
            {{ i18n.$t('Add a group') }}
          </Button>
        </div>
        <DataTable
          :value="groups"
          dataKey="_id"
          :rowHover="true"
          class="apin-data-table p-mt-2"
          v-model:selection="selected"
        >
          <template #header>
            <div class="table-header">{{ i18n.$t('List of Group') }}</div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
          <Column header="Name">
            <template #body="slotProps">
              <span>{{ slotProps.data.name }}</span>
            </template>
          </Column>
          <Column header="Type">
            <template #body="slotProps">
              <span>{{ slotProps.data._type }}</span>
            </template>
          </Column>
          <Column header="Case">
            <template #body="slotProps">
              <span v-if="slotProps.data._case">{{
                slotProps.data._case
              }}</span>
            </template>
          </Column>
          <Column header="Contact">
            <template #body="slotProps">
              <span v-if="slotProps.data.__contacts_id">{{
                slotProps.data.__contacts_id.length
              }}</span>
            </template>
          </Column>
          <Column :exportable="false">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                v-if="can('v1.group.update')"
                @click="
                  $router.push({
                    path: '/groups/edit',
                    query: {
                      group: slotProps.data._id
                    }
                  })
                "
              />
              <ButtonConfirm @accept="onDelete(slotProps.data._id)" v-if="can('v1.group.delete')">
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger"
                />
              </ButtonConfirm>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import {
  FETCH_GROUPS,
  GET_GROUPS,
  GET_USERDATA
} from '@/store/types';
import useI18n from '@/plugins/useI18n';
import { API } from '@/plugins/api';
export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [
      { label: 'My Clients', to: '/contacts' },
      { label: 'My Groups', to: '/groups/list' }
    ]
  }),
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const groups = computed(() => store.getters[GET_GROUPS]);
    const userData = computed(() => store.getters[GET_USERDATA]);

    const selected = ref([]);
    store.dispatch(FETCH_GROUPS, userData.value.id);

    const api = new API();
    function onDelete(id) {
      api.delete('group', id, FETCH_GROUPS);
    }

    return { 
      i18n, 
      groups, 
      selected, 
      onDelete 
    };
  }
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}
td {
  padding: 5px;
}
</style>
