<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <TopBar
          :title="i18n.$t('My Profile')"
          :breadcrumbItems="breadcrumbItems"
        />
        <div class="card avatar-wrapper">
          <AvatarLogo
            :data="userData"
            :edit="true"
            @changeLogo="avatar = $event"
          />
        </div>
        <form @submit.prevent="validation(true)">
          <div class="p-fluid p-mt-3">
            <h5>{{ i18n.$t('Update your profile') }}</h5>
            <div class="p-formgrid p-grid">
              <div class="p-field p-col">
                <label for="name2">{{ i18n.$t('Email') }}</label>
                <InputGroup :invalid="errors.email" :validation="valid.email">
                  <InputText
                    type="text"
                    v-model="userData.email"
                    @blur="(valid.email = true), validation()"
                  />
                </InputGroup>
              </div>
              <div class="p-field p-col">
                <label for="email2"> {{ i18n.$t('Username') }} </label>
                <InputText
                  type="text"
                  v-model="userData.username"
                  required
                  readonly
                />
              </div>
            </div>
            <div class="p-formgrid p-grid">
              <div class="p-field p-col">
                <label for="name2">{{ i18n.$t('First Name') }}</label>
                <InputGroup
                  :invalid="errors.first_name"
                  :validation="valid.first_name"
                >
                  <InputText
                    type="text"
                    v-model="userData.first_name"
                    required
                    @blur="(valid.first_name = true), validation()"
                  />
                </InputGroup>
              </div>
              <div class="p-field p-col">
                <label for="email2">{{ i18n.$t('Last Name') }}</label>
                <InputGroup
                  :invalid="errors.last_name"
                  :validation="valid.last_name"
                >
                  <InputText
                    type="text"
                    v-model="userData.last_name"
                    required
                    @blur="(valid.last_name = true), validation()"
                  />
                </InputGroup>
              </div>
            </div>

            <div class="p-formgrid p-grid">
              <div class="p-field p-col">
                <label for="email2">{{ i18n.$t('Role') }}</label>
                <InputText type="text" v-model="userData.role" readonly />
              </div>
              <div class="p-field p-col">
                <label for="phone">{{ i18n.$t('Phone') }}</label>
                <InputGroup
                  class="phone-select"
                  :invalid="errors.phone"
                  :validation="valid.phone"
                >
                  <input-phone
                    v-model="userData.phone"
                    type="text"
                    placeholder="Phone Number"
                    name="phone"
                    @onBlur="(valid.phone = true), validation()"
                    @prefix="userData.prefix = $event"
                  />
                </InputGroup>
              </div>
            </div>
            <div class="p-formgrid p-grid">
              <div class="p-field p-col">
                <label for="name2">{{ i18n.$t('Language') }}</label>
                <Dropdown
                  v-model="userData.language_id"
                  :options="langs"
                  optionLabel="name"
                  optionValue="name"
                  name="language"
                ></Dropdown>
              </div>
              <div class="p-field p-col-6">
                <Currency
                  v-if="userData.currency_id"
                  :item="userData.currency_id"
                  @select="userData.currency_id = $event"
                />
              </div>
            </div>
            <div class="p-formgrid p-grid select-address">
              <div class="p-field p-col-6">
                <label for="name2">{{ i18n.$t('Address') }}</label>
                <InputGroup
                  :invalid="address.fullAddress.length == 0"
                  :validation="valid.address"
                  @click="showAddress = true"
                >
                  <InputText
                    type="text"
                    v-model="userData.address"
                    name="address"
                    class="p-pr-5"
                  />
                  <div class="overlay"></div>
                  <i class="pi pi-arrow-right" v-if="!valid.address"></i>
                </InputGroup>
              </div>
              <div class="p-field p-col-6">
                <SelectAddress
                  :address="address"
                  :country="country.name"
                  v-show="showAddress"
                  @selected="
                    (userData.address = $event),
                      (valid.address = true),
                      validation()
                  "
                />
              </div>
            </div>
          </div>
          <div class="p-grid card">
            <div class="box p-mr-3">
              <Button type="button" label="Update" @click="validation(true)">
                <spinner class="spinner" v-if="isLoading" color="white" />
                <span v-if="!isLoading">{{ i18n.$t('Update') }}</span>
              </Button>
            </div>
            <div class="box p-mr-3">
              <Button
                type="button"
                label="Update"
                @click="$router.push({ path: '/profile/myprofile' })"
              >
                <span>{{ i18n.$t('Cancel') }}</span>
              </Button>
            </div>
            <div class="box">
              <Button
                type="button"
                label="Update"
                @click="$router.push({ path: '/public/changepassword' })"
              >
                <span>{{ i18n.$t('Change Password') }}</span>
              </Button>
            </div>
          </div>

          <div v-if="showResult" class="font-semibold mt-4">
            <p v-if="isError" class="text-red-700">
              {{ i18n.$t('Oops, something went wrong!') }}
            </p>
            <p v-if="!isError" class="text-green-700">
              {{ i18n.$t('Saved successfully') }}
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, reactive } from 'vue';
import { LANGS } from '@/store/constant';
import { useStore } from 'vuex';
import { UPDATE_USER, SET_LANGUAGE, GET_USERDATA } from '@/store/types';
import Spinner from '@/components/UI/Spinner.vue';
import TopBar from '@/components/Utilities/TopBarBreadCrumb';
import useI18n from '@/plugins/useI18n';
import InputGroup from '@/components/UI/InputGroup';
import SelectAddress from '@/components/Register/SelectAddress';
import AvatarLogo from '@/components/UI/AvatarLogo';
import Currency from '@/components/UI/Currency';
import * as yup from 'yup';

export default {
  data: () => ({
    breadcrumbItems: [
      { label: 'Organisation', to: '/agency' },
      { label: 'Profile', to: '/profile/myprofile' },
      { label: 'Employee', to: '/employee/list' }
    ]
  }),
  components: {
    Spinner,
    TopBar,
    InputGroup,
    SelectAddress,
    AvatarLogo,
    Currency
  },
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const langs = ref(LANGS);
    const isLoading = ref(false);
    const showResult = ref(false);
    const isError = ref(false);

    const showAddress = ref(false);

    const address = reactive({
      addressReq: '',
      fullAddress: ''
    });

    const country = ref('');

    const valid = ref({
      email: false,
      first_name: false,
      last_name: false,
      phone: false,
      address: false
    });

    const errors = ref({});
    const avatar = ref('');

    const userData = computed(() => store.getters[GET_USERDATA]);

    let schema = yup.object().shape({
      email: yup.string().email().required(),
      first_name: yup.string().min(3).required(),
      last_name: yup.string().min(3).required(),
      phone: yup.string().min(9).required(),
      address: yup.string().min(3).required()
    });

    function validation(click) {
      schema
        .validate(
          {
            email: userData.value.email,
            first_name: userData.value.first_name,
            last_name: userData.value.last_name,
            phone: userData.value.phone
              ? userData.value.phone.replace(/\s+/g, '')
              : null,
            address: userData.value.address
          },
          { abortEarly: false }
        )
        .then(() => {
          errors.value = {};
          if (click) {
            onSave();
          }
        })
        .catch((err) => {
          errors.value = {};
          err.inner.forEach((el) => {
            errors.value[el.path] = true;
          });

          if (click) {
            for (let key in valid) {
              valid.value[key] = true;
              console.log(valid.value[key]);
            }
          }
        });
    }

    async function onSave() {
      showResult.value = false;
      isError.value = false;
      isLoading.value = true;
      try {
        userData.value.language_id = userData.value.language_id;

        userData.value['avatar'] = avatar.value;

        await store.commit(SET_LANGUAGE, userData.value.language_id);

        await store.dispatch(UPDATE_USER, userData.value);

        showResult.value = true;
        isError.value = false;
        for (let key in valid.value) {
          valid.value[key] = false;
        }
      } catch {
        showResult.value = true;
        isError.value = true;
      }
      isLoading.value = false;
    }

    return {
      i18n,
      userData,
      avatar,
      langs,
      isLoading,
      showResult,
      isError,
      onSave,
      validation,
      valid,
      errors,
      showAddress,
      country,
      address
    };
  }
};
</script>

<style lang="scss" scoped>
.card {
  padding: 1em 0.5rem;
}
.select-address {
  ::v-deep(.font-semibold) {
    font-weight: 400;
    margin-bottom: 0.5rem;
    line-height: 17px;
    vertical-align: middle;
    &:first-of-type {
      margin-top: 0 !important;
    }
  }
}
.card {
  .avatar {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.avatar-wrapper {
  padding-top: 0;
  .p-avatar.p-avatar-xl {
    width: 7rem;
    height: 7rem;
  }
}
.p-button {
  max-height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner {
    max-height: 100%;
    margin: 0 !important;
  }
}
</style>
