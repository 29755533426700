<template>
  <div>
    <div class="form-input p-mt-3">
      <p class="">{{ i18n.$t('Select Service') }}</p>
      <MultiSelect
        name="service"
        v-model="service_id"
        :options="services"
        optionLabel="name"
        optionValue="_id"
        required
        style="width: 90%"
      />
    </div>
    <div class="form-input p-mt-4">
      <Button type="submit" class="loading-btn" @click="assignService" >
        <span >{{ i18n.$t('Assign') }}</span>
      </Button>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import useI18n from '@/plugins/useI18n';

import {
  GET_SERVICES,
  GET_EMPLOYEES
} from '@/store/types';
export default {
  setup(props, { emit }) {
    const { i18n } = useI18n();
    const store = useStore();
    const service_id = ref()

    const services = computed(() => store.getters[GET_SERVICES]);

    async function assignService(){
      emit('assign', service_id);
    }

    return{
      services,
      i18n,
      service_id,
      store,
      assignService,
    }
  }
}
</script>

<style>

</style>