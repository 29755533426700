import {UserDataInfo} from '@/plugins/api'
import { getBaseSchedule } from '@/store/data/schedule';

const userData = new UserDataInfo();
export default class EmployeeModel {
    constructor(item) {
        this.owner_id = userData.ownerId();
        this.organisation_id = userData.organisationId();
        this.first_name = item.first_name ? item.first_name : null;
        this.last_name = item.last_name ? item.last_name : null;
        this.address = item.address ? item.address : null,
        this.email = item.email ? item.email : null,
        this.phone = item.phone ? item.phone : null,
        this.username = item.username ? item.username : new Date().getTime(),
        this.language_id = item.language_id ? item.language_id : 'fr',
        this.currency_id = item.currency_id ? item.currency_id : 'Eur',
        this.status = item.status ? item.status : 10,
        this.role = item.role ? item.role : 'manager',
        this.schedule = item.schedule ? item.schedule : getBaseSchedule()
    }
}
