<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mt-4 p-mb-4"
      />
      <div class="card">
        <div>
          <Button
            class="p-mr-2"
            @click="$router.push({ path: '/employee/add' })"
          >
            {{ i18n.$t('Add a employee') }}
          </Button>
          <Button
            class="p-mr-2"
            @click="$router.push({ path: '/employee/invit' })"
          >
            {{ i18n.$t('Invit') }}
          </Button>
          <Button
            class="p-mr-2"
            @click="showModal = true"
          >
            {{ i18n.$t('Assign Service') }}
          </Button>
        </div>

        <DataTable
          :value="employees"
          dataKey="id"
          :rowHover="true"
          class="apin-data-table p-mt-2"
          v-model:selection="selected"
        >
          <Column v-model:selection="selected" selectionMode="multiple" headerStyle="width: 3em"></Column>
          <Column header="Name">
            <template #body="slotProps">
              <span
                >{{ slotProps.data.first_name }}&nbsp;
                {{ slotProps.data.last_name }}
              </span>
            </template>
          </Column>
          <Column field="role" header="Role"></Column>
          <Column field="services" header="Services">
            <template #body="slotProps">
              <span v-for="(service, SI) in slotProps.data.services" :key="SI" style="cursor:pointer">
                <span 
                  @click="
                    $router.push({
                      path: '/service/view/step1',
                      query: {
                        service: service.id
                      }
                    })
                  "
                >{{ service.name }}</span> <br> 
              </span>
            </template>
          </Column>
          <Column field="phone" header="Phone"></Column>
          <Column field="email" header="Email"></Column>
          <Column :exportable="false">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="
                  $router.push({
                    path: '/employee/edit',
                    query: {
                      employee: slotProps.data.id
                    }
                  })
                "
              />
              <Button
                icon="pi pi-clock"
                class="p-button-rounded p-button-primary p-mr-2"
                @click="
                  $router.push({
                    path: '/employee/schedule',
                    query: {
                      employee: slotProps.data.id
                    }
                  })
                "
              />
              <Button
                icon="pi pi-calendar"
                class="p-button-rounded p-button-help p-mr-2"
                @click="
                  $router.push({
                    path: '/events/tui-calendar',
                    query: {
                      employee: slotProps.data.id
                    }
                  })
                "
              />
              <ButtonConfirm
                @accept="onDelete(slotProps.data.id)"
                v-if="slotProps.data.role != 'director'"
              >
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger"
                />
              </ButtonConfirm>
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="modal-footer">
        <div class="p-mt-4">
          <transition-group name="p-messages" tag="div">
            <Message
              v-for="msg of message"
              :severity="msg.severity"
              :key="msg.content"
            >
              {{ msg.content }}
            </Message>
          </transition-group>
        </div>
      </div>
    </div>
    <Dialog
      header="Assign Service to Employee"
      v-model:visible="showModal"
      :style="{ width: '50vw' }"
      :modal="true"
      class="p-mt-2"
    >
      <AddService @assign="assign" />
    </Dialog>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import axios from '@/plugins/axios';
import { FETCH_EMPLOYEES, GET_EMPLOYEES, GET_USERDATA } from '@/store/types';
import { API } from '@/plugins/api';
import useI18n from '@/plugins/useI18n';
import AddService from '@/views/Employee/Modal/AddService'
export default {
  components:{
    AddService
  },
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/' },
    breadcrumbItems: [{ label: 'Employee list', to: '/employee/list' }],
    selectiontrue: false
  }),
  setup() {
    const showModal = ref(false);
    const { i18n } = useI18n();
    const store = useStore();
    const employees = computed(() => store.getters[GET_EMPLOYEES]);
    const userData = computed(() => store.getters[GET_USERDATA]);
    const selectedEmployee = ref([])
    const selected = ref([]);
    const message = ref([]);

    initData();

    async function initData() {
      await store.dispatch(FETCH_EMPLOYEES, userData.value.organisation_id);
    }
    const api = new API();

    async function onDelete(id) {
      api.delete('user', id, FETCH_EMPLOYEES, 'org');
    }

    async function assign(service){
      let assign_service_id = []
      let assign_employee_id = []
      selected.value.filter(e=> assign_employee_id.push(e.id))
      service.value.filter(e=> assign_service_id.push(e))
      showModal.value = false

      let assignServiceToEmployee ={
        __services_id: assign_service_id,
        __employees_id: assign_employee_id
      }
      message.value = [];
      try {
        await axios.post('user/assign-employee', assignServiceToEmployee);
        message.value = [
          { severity: 'success', content: 'Assigned successfully' }
        ];
      } catch {
        message.value = [
          { severity: 'error', content: 'Oops, something went wrong!' }
        ];
      }
    }

    return {
      employees,
      selected,
      i18n,
      onDelete,
      showModal,
      assign,
      message
    };
  }
};
</script>

<style lang="scss" scoped>
button:disabled {
  background: #cccccc;
}
.groups-in-table {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  li {
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
  }
}
.modal-footer {
  position: absolute;
  top: 100px;
  left: 50%;
}
</style>
