<template>
  <div>
    this is Icalendar Test
    <Button @click="onGenerate">{{ i18n.$t("Generate ICalendar") }}</Button>
    <Button @click="onMakeICS">{{ i18n.$t("MMake ICSy") }}</Button>
    <pre>{{ calString }}</pre>
  </div>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment';
import useI18n from '@/plugins/useI18n';
const ical = require('ical-generator');
export default {
  setup() {
    const { i18n } = useI18n();
    const calString = ref();

    function onGenerate() {
      const cal = ical({
        domain: 'sebbo.net',
        prodId: {
          company: 'superman-industries.com',
          product: 'ical-generator'
        },
        name: 'My Testfeed',
        timezone: 'Europe/Berlin'
      });
      calString.value = cal.toString();
    }

    function onMakeICS() {
      const cal = ical({ domain: 'github.com', name: 'my first iCal' });
      cal.createEvent({
        start: moment(),
        end: moment().add(1, 'hour'),
        summary: 'Example Event',
        description: 'It works ;)',
        contact: 'my conteact',
        location: 'my room',
        url: 'http://sebbo.net/'
      });
      calString.value = cal.toString();
      window.open('data:text/calendar;charset=utf8,' + escape(calString.value));
    }

    return { i18n, calString, onGenerate, onMakeICS };
  }
};
</script>

<style></style>
